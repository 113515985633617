import {Suspense, lazy} from 'react';
import {Navigate} from 'react-router-dom';
import {RouteObject} from 'react-router';

import MainLayout from './layouts/PageLayout';
import StoreLayout from './layouts/StoreLayout';
import AdminLayout from './layouts/AdminLayout';
import SuspenseLoader from "./components/SuspenseLoader";
import NotFound from "./pages/NotFound";
import ProtectedRoute from "./content/Security/ProtectedRoute";
import EditorLayout from "./layouts/EditorLayout";


const Loader = (Component: any) => (props: any) =>
    (
        <Suspense fallback={<SuspenseLoader/>}>
            <Component {...props} />
        </Suspense>
    );

// Pages

const Home = Loader(lazy(() => import('./pages/Home')));
const BlogPage = Loader(lazy(() => import('./pages/Blog')));
const Login = Loader(lazy(() => import('./pages/Login')));
const AdminLogin = Loader(lazy(() => import('./pages/Admin/Login')));
const About = Loader(lazy(() => import('./pages/About')));
const Clubs = Loader(lazy(() => import('./pages/Clubs')));
const FindAET = Loader(lazy(() => import('./pages/FindAet')));


const Agenda = Loader(lazy(() => import('./pages/AETLife')));
const AllActivities = Loader(lazy(() => import('./pages/AllActivities')));
const Placeholder = Loader(lazy(() => import('./pages/Placeholder')));
const AdminDashboard = Loader(lazy(() => import('./pages/Admin/Dashboard')));
const AdminBlog = Loader(lazy(() => import('./pages/Admin/BlogManagement')));
const AdminAgenda = Loader(lazy(() => import('./pages/Admin/AgendaManagement')));
const AdminPostRequestManagement = Loader(lazy(() => import('./pages/Admin/PostRequest')));
const UserManagement = Loader(lazy(() => import('./pages/Admin/UserManagement')));
const AdhesionRequestPage = Loader(lazy(() => import('./pages/Admin/AdhesionRequest')));

const NewsManagement = Loader(lazy(() => import('./pages/Admin/NewsManagement')));

const StoreManagement = Loader(lazy(() => import('./pages/Admin/StoreManagement')));





const OrdersManagement = Loader(lazy(() => import('./pages/Admin/Orders')));

const OrderDetails = Loader(lazy(() => import('./pages/Admin/Orders/OrderDetails')));


const EditPost = Loader(lazy(() => import('./pages/EditPost')));
const ShowPost = Loader(lazy(() => import('./pages/ShowPost')));

const ShowEvent = Loader(lazy(() => import('./pages/ShowEvent')));

const ShowNews = Loader(lazy(() => import('./pages/ShowNews')));

const NotreHistoire = Loader(lazy(() => import('./pages/NotreHistoire')));



const StoreHome = Loader(lazy(() => import('./pages/Store/Home')));
const AllProducts = Loader(lazy(() => import('./pages/Store/AllProducts')));
const Checkout = Loader(lazy(() => import('./pages/Store/Checkout')));

const AdhesionPage = Loader(lazy(() => import('./pages/PageAdhesion')));





// const ProtectedRoute = ({ user, children }:any) => {
//     if (!user) {
//         return <Navigate to="/login" replace />;
//     }
//
//     return children;
// };

const routes: RouteObject[] = [
    {
        path: '',
        element:
            <ProtectedRoute admin={false}  guest={true}>
                <MainLayout/>
            </ProtectedRoute>
        ,
        children: [
            {
                path: '',
                element: <Navigate to="home" replace/>
            },
            {
                path: 'login',
                element: <Navigate to="/auth" replace/>
            },
            {
                path: 'adhesion-2025',
                element: <AdhesionPage/>
            },
            {
                path: '*',
                element: <NotFound/>
            },
            {
                path: 'home',
                element: <Home/>
            },
            {
                path: 'about',
                element: <About/>
            },
            {
                path: 'organisation',
                element: <About/>
            },
            {
                path: 'clubs',
                element: <Clubs/>
            },
            {
                path: 'life',
                element: <Agenda/>
            },
            {
                path: 'life/tout',
                element: <AllActivities/>
            },
            {
                path: 'find-aet',
                element: <FindAET/>
            },
            {
                path: 'notre-histoire',
                element: <NotreHistoire/>
            },
            {
                path: 'blog',
                element: <BlogPage />
            },
            {
                path: 'blog/posts/:id/:title',
                element: <ShowPost />
            },
            {
                path: 'life/events/:id/:title',
                element: <ShowEvent />
            },
            {
                path: 'life/news/:id/:title',
                element: <ShowNews />
            },
        ]
    },
    {
        path: 'admin',
        element:
            <ProtectedRoute admin={true}>
                <AdminLayout/>
            </ProtectedRoute>
        ,
        children: [
            {
                path: '',
                element: <Navigate to="dashboard" replace/>
            },
            {
                path: 'dashboard',
                element: <AdminDashboard/>
            },
            {
                path: 'users',
                element: <UserManagement/>
            },
            {
                path: 'adhesion',
                element: <AdhesionRequestPage/>
            },
            {
                path: 'blog',
                element: <AdminBlog/>
            },
            // {
            //     path: 'blog/edit/:id',
            //     element: <EditPost/>
            // },
            // {
            //     path: 'blog/new',
            //     element: <EditPost/>
            // },
            {
                path: 'agenda',
                element: <AdminAgenda/>
            },
            {
                path: 'aet-news',
                element: <NewsManagement/>
            },
            {
                path: 'store',
                element: <StoreManagement/>
            },
            {
                path: 'store/orders',
                element: <OrdersManagement/>,
            },
            {
                path: 'store/orders/:id',
                element: <OrderDetails />
            },
            {
                path: 'post-request',
                element: <AdminPostRequestManagement />
            },
            {
                path: '*',
                element: <NotFound/>
            }
        ]
    },
    {
        path: 'auth',
        children: [
            {
                path: '',
                element: <Navigate to="login" replace/>
            },
            {
                path: 'login',
                element: <Login/>
            },
            {
                path: 'admin/login',
                element: <AdminLogin/>
            }
        ]
    },
    {
        path: 'forms',
        children: [
            {
                path: '',
                element: <Navigate to="auth/login" replace/>
            },
        ]
    },
    {
        path: 'posts',
        element: <ProtectedRoute admin={true}>
                    <EditorLayout/>
                </ProtectedRoute>,
        children: [
            {
                path: 'new',
                element: <EditPost />
            },
            {
                path: 'edit/:id',
                element: <EditPost edit={true}/>
            }
        ]
    },
    {
        path: 'store',
        element:
            <ProtectedRoute admin={false}  guest={true}>
                <StoreLayout/>
            </ProtectedRoute>
        ,
        children: [
            {
                path: '',
                element: <StoreHome/>
            },
            {
                path: 'login',
                element: <Navigate to="/auth" replace/>
            },
            {
                path: '*',
                element: <NotFound/>
            },
            {
                path: 'all',
                element: <AllProducts/>
            },
            {
                path: 'checkout/:id',
                element: <Checkout />
            },
            {
                path: 'blog/posts/:id/:title',
                element: <ShowPost />
            },
        ]
    },
];

export default routes;
